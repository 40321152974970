import type { Ref } from 'vue';

export function persistToSessionStorage(key: string, value: Ref) {
  sessionStorage.setItem(key, JSON.stringify(value.value));
}

export function getSessionStorage(key: string) {
  // @ts-expect-error this is okay but check later
  return JSON.parse(sessionStorage.getItem(key));
}
