export interface Country {
  name: string;
  nameNative?: string;
  iso2: string;
  id: number;
  dialCode: string;
  areaCodes?: string[];
  langNative?: string;
  langIso?: string;
}

export const allCountries: Country[] = [
  {
    name: 'Afghanistan',
    nameNative: '‫افغانستان',
    iso2: 'af',
    id: 4,
    dialCode: '93',
  },
  {
    name: 'Aland Islands',
    nameNative: 'Åland Islands',
    iso2: 'ax',
    id: 248,
    dialCode: '358',
  },
  {
    name: 'Albania',
    nameNative: 'Shqipëri',
    iso2: 'al',
    id: 8,
    dialCode: '355',
  },
  {
    name: 'Algeria',
    nameNative: 'الجزائر',
    iso2: 'dz',
    id: 12,
    dialCode: '213',
  },
  {
    name: 'American Samoa',
    nameNative: '',
    iso2: 'as',
    id: 16,
    dialCode: '1684',
  },
  {
    name: 'Andorra',
    nameNative: '',
    iso2: 'ad',
    id: 20,
    dialCode: '376',
  },
  {
    name: 'Angola',
    nameNative: '',
    iso2: 'ao',
    id: 24,
    dialCode: '244',
  },
  {
    name: 'Anguilla',
    nameNative: '',
    iso2: 'ai',
    id: 660,
    dialCode: '1264',
  },
  {
    name: 'Antigua and Barbuda',
    nameNative: '',
    iso2: 'ag',
    id: 28,
    dialCode: '1268',
  },
  {
    name: 'Argentina',
    nameNative: '',
    iso2: 'ar',
    id: 32,
    dialCode: '54',
  },
  {
    name: 'Armenia',
    nameNative: 'Հայաստան',
    iso2: 'am',
    id: 51,
    dialCode: '374',
  },
  {
    name: 'Aruba',
    nameNative: '',
    iso2: 'aw',
    id: 533,
    dialCode: '297',
  },
  {
    name: 'Australia',
    nameNative: '',
    iso2: 'au',
    id: 36,
    dialCode: '61',
  },
  {
    name: 'Austria',
    nameNative: 'Österreich',
    iso2: 'at',
    id: 40,
    dialCode: '43',
  },
  {
    name: 'Azerbaijan',
    nameNative: 'Azərbaycan',
    iso2: 'az',
    id: 31,
    dialCode: '994',
  },
  {
    name: 'Bahamas',
    nameNative: '',
    iso2: 'bs',
    id: 44,
    dialCode: '1242',
  },
  {
    name: 'Bahrain',
    nameNative: '‫البحرين',
    iso2: 'bh',
    id: 48,
    dialCode: '973',
  },
  {
    name: 'Bangladesh',
    nameNative: 'বাংলাদেশ',
    iso2: 'bd',
    id: 50,
    dialCode: '880',
  },
  {
    name: 'Barbados',
    nameNative: '',
    iso2: 'bb',
    id: 52,
    dialCode: '1246',
  },
  {
    name: 'Belarus',
    nameNative: 'Беларусь',
    iso2: 'by',
    id: 112,
    dialCode: '375',
  },
  {
    name: 'Belgium',
    nameNative: 'België',
    iso2: 'be',
    id: 56,
    dialCode: '32',
  },
  {
    name: 'Belize',
    nameNative: '',
    iso2: 'bz',
    id: 84,
    dialCode: '501',
  },
  {
    name: 'Benin',
    nameNative: 'Bénin',
    iso2: 'bj',
    id: 204,
    dialCode: '229',
  },
  {
    name: 'Bermuda',
    nameNative: '',
    iso2: 'bm',
    id: 60,
    dialCode: '1441',
  },
  {
    name: 'Bhutan',
    nameNative: 'འབྲུག',
    iso2: 'bt',
    id: 64,
    dialCode: '975',
  },
  {
    name: 'Bolivia',
    nameNative: '',
    iso2: 'bo',
    id: 68,
    dialCode: '591',
  },
  {
    name: 'Bosnia and Herzegovina',
    nameNative: 'Босна и Херцеговина',
    iso2: 'ba',
    id: 70,
    dialCode: '387',
  },
  {
    name: 'Botswana',
    nameNative: '',
    iso2: 'bw',
    id: 72,
    dialCode: '267',
  },
  {
    name: 'Bouvet Island',
    nameNative: '',
    iso2: 'bv',
    id: 74,
    dialCode: '47',
  },
  {
    name: 'Brazil',
    nameNative: 'Brasil',
    iso2: 'br',
    id: 76,
    dialCode: '55',
  },
  {
    name: 'British Indian Ocean Territory',
    nameNative: '',
    iso2: 'io',
    id: 86,
    dialCode: '246',
  },
  {
    name: 'British Virgin Islands',
    nameNative: '',
    iso2: 'vg',
    id: 92,
    dialCode: '1284',
  },
  {
    name: 'Brunei',
    nameNative: '',
    iso2: 'bn',
    id: 96,
    dialCode: '673',
  },
  {
    name: 'Bulgaria',
    nameNative: 'България',
    iso2: 'bg',
    id: 100,
    dialCode: '359',
  },
  {
    name: 'Burkina Faso',
    nameNative: '',
    iso2: 'bf',
    id: 854,
    dialCode: '226',
  },
  {
    name: 'Burundi',
    nameNative: 'Uburundi',
    iso2: 'bi',
    id: 108,
    dialCode: '257',
  },
  {
    name: 'Cambodia',
    nameNative: 'កម្ពុជា',
    iso2: 'kh',
    id: 116,
    dialCode: '855',
  },
  {
    name: 'Cameroon',
    nameNative: 'Cameroun',
    iso2: 'cm',
    id: 120,
    dialCode: '237',
  },
  {
    name: 'Canada',
    nameNative: '',
    iso2: 'ca',
    id: 124,
    dialCode: '1',
    areaCodes: [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  },
  {
    name: 'Cape Verde',
    nameNative: 'Kabu Verdi',
    iso2: 'cv',
    id: 132,
    dialCode: '238',
  },
  {
    name: 'Caribbean Netherlands',
    nameNative: '',
    iso2: 'bq',
    id: 535,
    dialCode: '599',
  },
  {
    name: 'Cayman Islands',
    nameNative: '',
    iso2: 'ky',
    id: 136,
    dialCode: '1345',
  },
  {
    name: 'Central African Republic',
    nameNative: 'République centrafricaine',
    iso2: 'cf',
    id: 140,
    dialCode: '236',
  },
  {
    name: 'Chad',
    nameNative: 'Tchad',
    iso2: 'td',
    id: 148,
    dialCode: '235',
  },
  {
    name: 'Chile',
    nameNative: '',
    iso2: 'cl',
    id: 152,
    dialCode: '56',
  },
  {
    name: 'China',
    nameNative: '中国',
    iso2: 'cn',
    id: 156,
    dialCode: '86',
  },
  {
    name: 'Christmas Island',
    nameNative: '',
    iso2: 'cx',
    id: 162,
    dialCode: '61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    nameNative: '',
    iso2: 'cc',
    id: 166,
    dialCode: '61',
  },
  {
    name: 'Colombia',
    nameNative: '',
    iso2: 'co',
    id: 170,
    dialCode: '57',
  },
  {
    name: 'Comoros',
    nameNative: '‫‫جزر القمر',
    iso2: 'km',
    id: 174,
    dialCode: '269',
  },
  {
    name: 'Congo (DRC)',
    nameNative: 'Jamhuri ya Kidemokrasia ya Kongo',
    iso2: 'cd',
    id: 180,
    dialCode: '243',
  },
  {
    name: 'Congo (Republic)',
    nameNative: 'Congo-Brazzaville',
    iso2: 'cg',
    id: 178,
    dialCode: '242',
  },
  {
    name: 'Cook Islands',
    nameNative: '',
    iso2: 'ck',
    id: 184,
    dialCode: '682',
  },
  {
    name: 'Costa Rica',
    nameNative: '',
    iso2: 'cr',
    id: 188,
    dialCode: '506',
  },
  {
    name: 'Cote d’Ivoire',
    nameNative: 'Côte d’Ivoire',
    iso2: 'ci',
    id: 384,
    dialCode: '225',
  },
  {
    name: 'Croatia',
    nameNative: 'Hrvatska',
    iso2: 'hr',
    id: 191,
    dialCode: '385',
  },
  {
    name: 'Cuba',
    nameNative: '',
    iso2: 'cu',
    id: 192,
    dialCode: '53',
  },
  {
    name: 'Curacao',
    nameNative: 'Curaçao',
    iso2: 'cw',
    id: 531,
    dialCode: '599',
  },
  {
    name: 'Cyprus',
    nameNative: 'Κύπρος',
    iso2: 'cy',
    id: 196,
    dialCode: '357',
  },
  {
    name: 'Czech Republic',
    nameNative: 'Česká republika',
    iso2: 'cz',
    id: 203,
    dialCode: '420',
  },
  {
    name: 'Denmark',
    nameNative: 'Danmark',
    iso2: 'dk',
    id: 208,
    dialCode: '45',
  },
  {
    name: 'Djibouti',
    nameNative: '',
    iso2: 'dj',
    id: 262,
    dialCode: '253',
  },
  {
    name: 'Dominica',
    nameNative: '',
    iso2: 'dm',
    id: 212,
    dialCode: '1767',
  },
  {
    name: 'Dominican Republic',
    nameNative: 'República Dominicana',
    iso2: 'do',
    id: 214,
    dialCode: '1',
    areaCodes: ['809', '829', '849'],
  },
  {
    name: 'Ecuador',
    nameNative: '',
    iso2: 'ec',
    id: 218,
    dialCode: '593',
  },
  {
    name: 'Egypt',
    nameNative: '‫مصر',
    iso2: 'eg',
    id: 818,
    dialCode: '20',
  },
  {
    name: 'El Salvador',
    nameNative: '',
    iso2: 'sv',
    id: 222,
    dialCode: '503',
  },
  {
    name: 'Equatorial Guinea',
    nameNative: 'Guinea Ecuatorial',
    iso2: 'gq',
    id: 226,
    dialCode: '240',
  },
  {
    name: 'Eritrea',
    nameNative: '',
    iso2: 'er',
    id: 232,
    dialCode: '291',
  },
  {
    name: 'Estonia',
    nameNative: 'Eesti',
    iso2: 'ee',
    id: 233,
    dialCode: '372',
  },
  {
    name: 'Ethiopia',
    nameNative: '',
    iso2: 'et',
    id: 231,
    dialCode: '251',
  },
  {
    name: 'Falkland Islands',
    nameNative: 'Islas Malvinas',
    iso2: 'fk',
    id: 238,
    dialCode: '500',
  },
  {
    name: 'Faroe Islands',
    nameNative: 'Føroyar',
    iso2: 'fo',
    id: 234,
    dialCode: '298',
  },
  {
    name: 'Fiji',
    nameNative: '',
    iso2: 'fj',
    id: 242,
    dialCode: '679',
  },
  {
    name: 'Finland',
    nameNative: 'Suomi',
    iso2: 'fi',
    id: 246,
    dialCode: '358',
  },
  {
    name: 'France',
    nameNative: '',
    iso2: 'fr',
    id: 250,
    dialCode: '33',
  },
  {
    name: 'French Guiana',
    nameNative: 'Guyane française',
    iso2: 'gf',
    id: 254,
    dialCode: '594',
  },
  {
    name: 'French Polynesia',
    nameNative: 'Polynésie française',
    iso2: 'pf',
    id: 258,
    dialCode: '689',
  },
  {
    name: 'Gabon',
    nameNative: '',
    iso2: 'ga',
    id: 266,
    dialCode: '241',
  },
  {
    name: 'Gambia',
    nameNative: '',
    iso2: 'gm',
    id: 270,
    dialCode: '220',
  },
  {
    name: 'Georgia',
    nameNative: 'საქართველო',
    iso2: 'ge',
    id: 268,
    dialCode: '995',
  },
  {
    name: 'Germany',
    nameNative: 'Deutschland',
    iso2: 'de',
    id: 276,
    dialCode: '49',
    langNative: 'Deutsch',
  },
  {
    name: 'Ghana',
    nameNative: 'Gaana',
    iso2: 'gh',
    id: 288,
    dialCode: '233',
  },
  {
    name: 'Gibraltar',
    nameNative: '',
    iso2: 'gi',
    id: 292,
    dialCode: '350',
  },
  {
    name: 'Greece',
    nameNative: 'Ελλάδα',
    iso2: 'gr',
    id: 300,
    dialCode: '30',
  },
  {
    name: 'Greenland',
    nameNative: 'Kalaallit Nunaat',
    iso2: 'gl',
    id: 304,
    dialCode: '299',
  },
  {
    name: 'Grenada',
    nameNative: '',
    iso2: 'gd',
    id: 308,
    dialCode: '1473',
  },
  {
    name: 'Guadeloupe',
    nameNative: '',
    iso2: 'gp',
    id: 312,
    dialCode: '590',
  },
  {
    name: 'Guam',
    nameNative: '',
    iso2: 'gu',
    id: 316,
    dialCode: '1671',
  },
  {
    name: 'Guatemala',
    nameNative: '',
    iso2: 'gt',
    id: 320,
    dialCode: '502',
  },
  {
    name: 'Guernsey',
    nameNative: '',
    iso2: 'gg',
    id: 831,
    dialCode: '44',
  },
  {
    name: 'Guinea',
    nameNative: 'Guinée',
    iso2: 'gn',
    id: 324,
    dialCode: '224',
  },
  {
    name: 'Guinea-Bissau',
    nameNative: 'Guiné Bissau',
    iso2: 'gw',
    id: 624,
    dialCode: '245',
  },
  {
    name: 'Guyana',
    nameNative: '',
    iso2: 'gy',
    id: 328,
    dialCode: '592',
  },
  {
    name: 'Haiti',
    nameNative: '',
    iso2: 'ht',
    id: 332,
    dialCode: '509',
  },
  {
    name: 'Heard Island and McDonald Islands',
    nameNative: '',
    iso2: 'hm',
    id: 334,
    dialCode: '672',
  },
  {
    name: 'Honduras',
    nameNative: '',
    iso2: 'hn',
    id: 340,
    dialCode: '504',
  },
  {
    name: 'Hong Kong',
    nameNative: '香港',
    iso2: 'hk',
    id: 344,
    dialCode: '852',
  },
  {
    name: 'Hungary',
    nameNative: 'Magyarország',
    iso2: 'hu',
    id: 348,
    dialCode: '36',
  },
  {
    name: 'Iceland',
    nameNative: 'Ísland',
    iso2: 'is',
    id: 352,
    dialCode: '354',
  },
  {
    name: 'India',
    nameNative: 'भारत',
    iso2: 'in',
    id: 356,
    dialCode: '91',
  },
  {
    name: 'Indonesia',
    nameNative: '',
    iso2: 'id',
    id: 360,
    dialCode: '62',
  },
  {
    name: 'Iran',
    nameNative: '‫ایران',
    iso2: 'ir',
    id: 364,
    dialCode: '98',
  },
  {
    name: 'Iraq',
    nameNative: '‫العراق',
    iso2: 'iq',
    id: 368,
    dialCode: '964',
  },
  {
    name: 'Ireland',
    nameNative: '',
    iso2: 'ie',
    id: 372,
    dialCode: '353',
  },
  {
    name: 'Isle of Man',
    nameNative: '',
    iso2: 'im',
    id: 833,
    dialCode: '44',
  },
  {
    name: 'Israel',
    nameNative: 'ישראל',
    iso2: 'il',
    id: 376,
    dialCode: '972',
  },
  {
    name: 'Italy',
    nameNative: 'Italia',
    iso2: 'it',
    id: 380,
    dialCode: '39',
  },
  {
    name: 'Jamaica',
    nameNative: '',
    iso2: 'jm',
    id: 388,
    dialCode: '1876',
  },
  {
    name: 'Japan',
    nameNative: '日本',
    iso2: 'jp',
    id: 392,
    dialCode: '81',
  },
  {
    name: 'Jersey',
    nameNative: '',
    iso2: 'je',
    id: 832,
    dialCode: '44',
  },
  {
    name: 'Jordan',
    nameNative: '‫الأردن',
    iso2: 'jo',
    id: 400,
    dialCode: '962',
  },
  {
    name: 'Kazakhstan',
    nameNative: 'Казахстан',
    iso2: 'kz',
    id: 398,
    dialCode: '7',
  },
  {
    name: 'Kenya',
    nameNative: '',
    iso2: 'ke',
    id: 404,
    dialCode: '254',
  },
  {
    name: 'Kiribati',
    nameNative: '',
    iso2: 'ki',
    id: 296,
    dialCode: '686',
  },
  {
    name: 'Kuwait',
    nameNative: '‫الكويت',
    iso2: 'kw',
    id: 414,
    dialCode: '965',
  },
  {
    name: 'Kyrgyzstan',
    nameNative: 'Кыргызстан',
    iso2: 'kg',
    id: 417,
    dialCode: '996',
  },
  {
    name: 'Laos',
    nameNative: 'ລາວ',
    iso2: 'la',
    id: 418,
    dialCode: '856',
  },
  {
    name: 'Latvia',
    nameNative: 'Latvija',
    iso2: 'lv',
    id: 428,
    dialCode: '371',
  },
  {
    name: 'Lebanon',
    nameNative: '‫لبنان',
    iso2: 'lb',
    id: 422,
    dialCode: '961',
  },
  {
    name: 'Lesotho',
    nameNative: '',
    iso2: 'ls',
    id: 426,
    dialCode: '266',
  },
  {
    name: 'Liberia',
    nameNative: '',
    iso2: 'lr',
    id: 430,
    dialCode: '231',
  },
  {
    name: 'Libya',
    nameNative: '‫ليبيا',
    iso2: 'ly',
    id: 434,
    dialCode: '218',
  },
  {
    name: 'Liechtenstein',
    nameNative: '',
    iso2: 'li',
    id: 438,
    dialCode: '423',
  },
  {
    name: 'Lithuania',
    nameNative: 'Lietuva',
    iso2: 'lt',
    id: 440,
    dialCode: '370',
  },
  {
    name: 'Luxembourg',
    nameNative: '',
    iso2: 'lu',
    id: 442,
    dialCode: '352',
  },
  {
    name: 'Macau',
    nameNative: '澳門',
    iso2: 'mo',
    id: 446,
    dialCode: '853',
  },
  {
    name: 'Macedonia (FYROM)',
    nameNative: 'Македонија',
    iso2: 'mk',
    id: 807,
    dialCode: '389',
  },
  {
    name: 'Madagascar',
    nameNative: 'Madagasikara',
    iso2: 'mg',
    id: 450,
    dialCode: '261',
  },
  {
    name: 'Malawi',
    nameNative: '',
    iso2: 'mw',
    id: 454,
    dialCode: '265',
  },
  {
    name: 'Malaysia',
    nameNative: '',
    iso2: 'my',
    id: 458,
    dialCode: '60',
  },
  {
    name: 'Maldives',
    nameNative: '',
    iso2: 'mv',
    id: 462,
    dialCode: '960',
  },
  {
    name: 'Mali',
    nameNative: '',
    iso2: 'ml',
    id: 466,
    dialCode: '223',
  },
  {
    name: 'Malta',
    nameNative: '',
    iso2: 'mt',
    id: 470,
    dialCode: '356',
  },
  {
    name: 'Marshall Islands',
    nameNative: '',
    iso2: 'mh',
    id: 584,
    dialCode: '692',
  },
  {
    name: 'Martinique',
    nameNative: '',
    iso2: 'mq',
    id: 474,
    dialCode: '596',
  },
  {
    name: 'Mauritania',
    nameNative: '‫موريتانيا',
    iso2: 'mr',
    id: 478,
    dialCode: '222',
  },
  {
    name: 'Mauritius',
    nameNative: 'Moris',
    iso2: 'mu',
    id: 480,
    dialCode: '230',
  },
  {
    name: 'Mayotte',
    nameNative: '',
    iso2: 'yt',
    id: 175,
    dialCode: '262',
  },
  {
    name: 'Mexico',
    nameNative: 'México',
    iso2: 'mx',
    id: 484,
    dialCode: '52',
  },
  {
    name: 'Micronesia',
    nameNative: '',
    iso2: 'fm',
    id: 583,
    dialCode: '691',
  },
  {
    name: 'Moldova',
    nameNative: 'Republica Moldova',
    iso2: 'md',
    id: 498,
    dialCode: '373',
  },
  {
    name: 'Monaco',
    nameNative: '',
    iso2: 'mc',
    id: 492,
    dialCode: '377',
  },
  {
    name: 'Mongolia',
    nameNative: 'Монгол',
    iso2: 'mn',
    id: 496,
    dialCode: '976',
  },
  {
    name: 'Montenegro',
    nameNative: 'Crna Gora',
    iso2: 'me',
    id: 499,
    dialCode: '382',
  },
  {
    name: 'Montserrat',
    nameNative: '',
    iso2: 'ms',
    id: 500,
    dialCode: '1664',
  },
  {
    name: 'Morocco',
    nameNative: '‫المغرب',
    iso2: 'ma',
    id: 504,
    dialCode: '212',
  },
  {
    name: 'Mozambique',
    nameNative: 'Moçambique',
    iso2: 'mz',
    id: 508,
    dialCode: '258',
  },
  {
    name: 'Myanmar (Burma)',
    nameNative: 'မြန်မာ',
    iso2: 'mm',
    id: 104,
    dialCode: '95',
  },
  {
    name: 'Namibia',
    nameNative: 'Namibië',
    iso2: 'na',
    id: 516,
    dialCode: '264',
  },
  {
    name: 'Nauru',
    nameNative: '',
    iso2: 'nr',
    id: 520,
    dialCode: '674',
  },
  {
    name: 'Nepal',
    nameNative: 'नेपाल',
    iso2: 'np',
    id: 524,
    dialCode: '977',
  },
  {
    name: 'Netherlands',
    nameNative: 'Nederland',
    iso2: 'nl',
    id: 528,
    dialCode: '31',
  },
  {
    name: 'New Caledonia',
    nameNative: 'Nouvelle-Calédonie',
    iso2: 'nc',
    id: 540,
    dialCode: '687',
  },
  {
    name: 'New Zealand',
    nameNative: '',
    iso2: 'nz',
    id: 554,
    dialCode: '64',
  },
  {
    name: 'Nicaragua',
    nameNative: '',
    iso2: 'ni',
    id: 558,
    dialCode: '505',
  },
  {
    name: 'Niger',
    nameNative: 'Nijar',
    iso2: 'ne',
    id: 562,
    dialCode: '227',
  },
  {
    name: 'Nigeria',
    nameNative: '',
    iso2: 'ng',
    id: 566,
    dialCode: '234',
  },
  {
    name: 'Niue',
    nameNative: '',
    iso2: 'nu',
    id: 570,
    dialCode: '683',
  },
  {
    name: 'Norfolk Island',
    nameNative: '',
    iso2: 'nf',
    id: 574,
    dialCode: '672',
  },
  {
    name: 'North Korea',
    nameNative: '조선 민주주의 인민 공화국',
    iso2: 'kp',
    id: 408,
    dialCode: '850',
  },
  {
    name: 'Northern Mariana Islands',
    nameNative: '',
    iso2: 'mp',
    id: 580,
    dialCode: '1670',
  },
  {
    name: 'Norway',
    nameNative: 'Norge',
    iso2: 'no',
    id: 578,
    dialCode: '47',
    langNative: 'Norsk',
  },
  {
    name: 'Oman',
    nameNative: '‫عُمان',
    iso2: 'om',
    id: 512,
    dialCode: '968',
  },
  {
    name: 'Pakistan',
    nameNative: '‫پاکستان',
    iso2: 'pk',
    id: 586,
    dialCode: '92',
  },
  {
    name: 'Palau',
    nameNative: '',
    iso2: 'pw',
    id: 585,
    dialCode: '680',
  },
  {
    name: 'Palestine',
    nameNative: '‫فلسطين',
    iso2: 'ps',
    id: 275,
    dialCode: '970',
  },
  {
    name: 'Panama',
    nameNative: 'Panamá',
    iso2: 'pa',
    id: 591,
    dialCode: '507',
  },
  {
    name: 'Papua New Guinea',
    nameNative: '',
    iso2: 'pg',
    id: 598,
    dialCode: '675',
  },
  {
    name: 'Paraguay',
    nameNative: '',
    iso2: 'py',
    id: 600,
    dialCode: '595',
  },
  {
    name: 'Peru',
    nameNative: 'Perú',
    iso2: 'pe',
    id: 604,
    dialCode: '51',
  },
  {
    name: 'Philippines',
    nameNative: '',
    iso2: 'ph',
    id: 608,
    dialCode: '63',
  },
  {
    name: 'Pitcairn',
    nameNative: '',
    iso2: 'pn',
    id: 612,
    dialCode: '64',
  },
  {
    name: 'Poland',
    nameNative: 'Polska',
    iso2: 'pl',
    id: 616,
    dialCode: '48',
  },
  {
    name: 'Portugal',
    nameNative: '',
    iso2: 'pt',
    id: 620,
    dialCode: '351',
  },
  {
    name: 'Puerto Rico',
    nameNative: '',
    iso2: 'pr',
    id: 630,
    dialCode: '1',
    areaCodes: ['787', '939'],
  },
  {
    name: 'Qatar',
    nameNative: '‫قطر',
    iso2: 'qa',
    id: 634,
    dialCode: '974',
  },
  {
    name: 'Reunion',
    nameNative: 'La Réunion',
    iso2: 're',
    id: 638,
    dialCode: '262',
  },
  {
    name: 'Romania',
    nameNative: 'România',
    iso2: 'ro',
    id: 642,
    dialCode: '40',
  },
  {
    name: 'Russia',
    nameNative: 'Россия',
    iso2: 'ru',
    id: 643,
    dialCode: '7',
  },
  {
    name: 'Rwanda',
    nameNative: '',
    iso2: 'rw',
    id: 646,
    dialCode: '250',
  },
  {
    name: 'Saint Barthelemy',
    nameNative: 'Saint-Barthélemy',
    iso2: 'bl',
    id: 652,
    dialCode: '590',
  },
  {
    name: 'Saint Helena',
    nameNative: '',
    iso2: 'sh',
    id: 654,
    dialCode: '290',
  },
  {
    name: 'Saint Kitts and Nevis',
    nameNative: '',
    iso2: 'kn',
    id: 659,
    dialCode: '1869',
  },
  {
    name: 'Saint Lucia',
    nameNative: '',
    iso2: 'lc',
    id: 662,
    dialCode: '1758',
  },
  {
    name: 'Saint Martin',
    nameNative: 'Saint-Martin (partie française)',
    iso2: 'mf',
    id: 663,
    dialCode: '590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    nameNative: 'Saint-Pierre-et-Miquelon',
    iso2: 'pm',
    id: 666,
    dialCode: '508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    nameNative: '',
    iso2: 'vc',
    id: 670,
    dialCode: '1784',
  },
  {
    name: 'Samoa',
    nameNative: '',
    iso2: 'ws',
    id: 882,
    dialCode: '685',
  },
  {
    name: 'San Marino',
    nameNative: '',
    iso2: 'sm',
    id: 674,
    dialCode: '378',
  },
  {
    name: 'São Tomé and Príncipe',
    nameNative: 'São Tomé e Príncipe',
    iso2: 'st',
    id: 678,
    dialCode: '239',
  },
  {
    name: 'Saudi Arabia',
    nameNative: '‫المملكة العربية السعودية',
    iso2: 'sa',
    id: 682,
    dialCode: '966',
  },
  {
    name: 'Senegal',
    nameNative: 'Sénégal',
    iso2: 'sn',
    id: 686,
    dialCode: '221',
  },
  {
    name: 'Serbia',
    nameNative: 'Србија',
    iso2: 'rs',
    id: 688,
    dialCode: '381',
  },
  {
    name: 'Seychelles',
    nameNative: '',
    iso2: 'sc',
    id: 690,
    dialCode: '248',
  },
  {
    name: 'Sierra Leone',
    nameNative: '',
    iso2: 'sl',
    id: 694,
    dialCode: '232',
  },
  {
    name: 'Singapore',
    nameNative: '',
    iso2: 'sg',
    id: 702,
    dialCode: '65',
  },
  {
    name: 'Sint Maarten',
    nameNative: '',
    iso2: 'sx',
    id: 534,
    dialCode: '1721',
  },
  {
    name: 'Slovakia',
    nameNative: 'Slovensko',
    iso2: 'sk',
    id: 703,
    dialCode: '421',
  },
  {
    name: 'Slovenia',
    nameNative: 'Slovenija',
    iso2: 'si',
    id: 705,
    dialCode: '386',
  },
  {
    name: 'Solomon Islands',
    nameNative: '',
    iso2: 'sb',
    id: 90,
    dialCode: '677',
  },
  {
    name: 'Somalia',
    nameNative: 'Soomaaliya',
    iso2: 'so',
    id: 706,
    dialCode: '252',
  },
  {
    name: 'South Africa',
    nameNative: '',
    iso2: 'za',
    id: 710,
    dialCode: '27',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    nameNative: '',
    iso2: 'gs',
    id: 239,
    dialCode: '500',
  },
  {
    name: 'South Korea',
    nameNative: '대한민국',
    iso2: 'kr',
    id: 410,
    dialCode: '82',
  },
  {
    name: 'South Sudan',
    nameNative: '‫جنوب السودان',
    iso2: 'ss',
    id: 728,
    dialCode: '211',
  },
  {
    name: 'Spain',
    nameNative: 'España',
    iso2: 'es',
    id: 724,
    dialCode: '34',
  },
  {
    name: 'Sri Lanka',
    nameNative: 'ශ්‍රී ලංකාව',
    iso2: 'lk',
    id: 144,
    dialCode: '94',
  },
  {
    name: 'Sudan',
    nameNative: '‫السودان',
    iso2: 'sd',
    id: 729,
    dialCode: '249',
  },
  {
    name: 'Suriname',
    nameNative: '',
    iso2: 'sr',
    id: 740,
    dialCode: '597',
  },
  {
    name: 'Svalbard and Jan Mayen',
    nameNative: '',
    iso2: 'sj',
    id: 744,
    dialCode: '47',
  },
  {
    name: 'Swaziland',
    nameNative: '',
    iso2: 'sz',
    id: 748,
    dialCode: '268',
  },
  {
    name: 'Sweden',
    nameNative: 'Sverige',
    iso2: 'se',
    id: 752,
    dialCode: '46',
    langNative: 'Svenska',
  },
  {
    name: 'Switzerland',
    nameNative: 'Schweiz',
    iso2: 'ch',
    id: 756,
    dialCode: '41',
  },
  {
    name: 'Syria',
    nameNative: '‫سوريا',
    iso2: 'sy',
    id: 760,
    dialCode: '963',
  },
  {
    name: 'Taiwan',
    nameNative: '台灣',
    iso2: 'tw',
    id: 158,
    dialCode: '886',
  },
  {
    name: 'Tajikistan',
    nameNative: '',
    iso2: 'tj',
    id: 762,
    dialCode: '992',
  },
  {
    name: 'Tanzania',
    nameNative: '',
    iso2: 'tz',
    id: 834,
    dialCode: '255',
  },
  {
    name: 'Thailand',
    nameNative: 'ไทย',
    iso2: 'th',
    id: 764,
    dialCode: '66',
  },
  {
    name: 'Timor-Leste',
    nameNative: '',
    iso2: 'tl',
    id: 626,
    dialCode: '670',
  },
  {
    name: 'Togo',
    nameNative: '',
    iso2: 'tg',
    id: 768,
    dialCode: '228',
  },
  {
    name: 'Tokelau',
    nameNative: '',
    iso2: 'tk',
    id: 772,
    dialCode: '690',
  },
  {
    name: 'Tonga',
    nameNative: '',
    iso2: 'to',
    id: 776,
    dialCode: '676',
  },
  {
    name: 'Trinidad and Tobago',
    nameNative: '',
    iso2: 'tt',
    id: 780,
    dialCode: '1868',
  },
  {
    name: 'Tunisia',
    nameNative: '‫تونس',
    iso2: 'tn',
    id: 788,
    dialCode: '216',
  },
  {
    name: 'Turkey',
    nameNative: 'Türkiye',
    iso2: 'tr',
    id: 792,
    dialCode: '90',
  },
  {
    name: 'Turkmenistan',
    nameNative: '',
    iso2: 'tm',
    id: 795,
    dialCode: '993',
  },
  {
    name: 'Turks and Caicos Islands',
    nameNative: '',
    iso2: 'tc',
    id: 796,
    dialCode: '1649',
  },
  {
    name: 'Tuvalu',
    nameNative: '',
    iso2: 'tv',
    id: 798,
    dialCode: '688',
  },
  {
    name: 'Uganda',
    nameNative: '',
    iso2: 'ug',
    id: 800,
    dialCode: '256',
  },
  {
    name: 'Ukraine',
    nameNative: 'Україна',
    iso2: 'ua',
    id: 804,
    dialCode: '380',
  },
  {
    name: 'United Arab Emirates',
    nameNative: '‫الإمارات العربية المتحدة',
    iso2: 'ae',
    id: 784,
    dialCode: '971',
  },
  {
    name: 'United Kingdom',
    nameNative: '',
    iso2: 'gb',
    id: 826,
    dialCode: '44',
    langNative: 'English',
    langIso: 'en',
  },
  {
    name: 'United States',
    nameNative: '',
    iso2: 'us',
    id: 840,
    dialCode: '1',
  },
  {
    name: 'Uruguay',
    nameNative: '',
    iso2: 'uy',
    id: 858,
    dialCode: '598',
  },
  {
    name: 'Uzbekistan',
    nameNative: 'Oʻzbekiston',
    iso2: 'uz',
    id: 860,
    dialCode: '998',
  },
  {
    name: 'Vanuatu',
    nameNative: '',
    iso2: 'vu',
    id: 548,
    dialCode: '678',
  },
  {
    name: 'Vatican City',
    nameNative: 'Città del Vaticano',
    iso2: 'va',
    id: 336,
    dialCode: '39',
  },
  {
    name: 'Venezuela',
    nameNative: '',
    iso2: 've',
    id: 862,
    dialCode: '58',
  },
  {
    name: 'Vietnam',
    nameNative: 'Việt Nam',
    iso2: 'vn',
    id: 704,
    dialCode: '84',
  },
  {
    name: 'Virgin Islands',
    nameNative: '',
    iso2: 'vi',
    id: 850,
    dialCode: '1340',
  },
  {
    name: 'Wallis and Futuna',
    nameNative: 'Wallis-et-Futuna',
    iso2: 'wf',
    id: 876,
    dialCode: '681',
  },
  {
    name: 'Western Sahara',
    nameNative: '‫الصحراء الغربية',
    iso2: 'eh',
    id: 732,
    dialCode: '212',
  },
  {
    name: 'Yemen',
    nameNative: '‫اليمن',
    iso2: 'ye',
    id: 887,
    dialCode: '967',
  },
  {
    name: 'Zambia',
    nameNative: '',
    iso2: 'zm',
    id: 894,
    dialCode: '260',
  },
  {
    name: 'Zimbabwe',
    nameNative: '',
    iso2: 'zw',
    id: 716,
    dialCode: '263',
  },
];
