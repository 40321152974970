import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosResponse,
} from 'axios';

import type {
  CheckoutStatus,
  ICheckoutCreate,
  OCheckout,
} from './types/checkout';
import type { NoContent } from './types/generic';
import type { OOrderGroup } from './types/order-groups';
import type { IReservation, Reservation } from './types/reservations';
import type { ITerms, OSetup, OTerms } from './types/setup';
import type { Customer, User } from './types/user';
import type {
  IWebProductList,
  OWebProdcutList,
  PriceAgeGroup,
  ProductItem,
} from './types/webproducts';

import { allCountries, type Country } from './countries';
import { CheckoutErrors, PaymentTypes } from './types/checkout';

export { CheckoutErrors, PaymentTypes };

export type {
  CheckoutStatus,
  Customer,
  ICheckoutCreate,
  IReservation,
  ITerms,
  IWebProductList,
  OCheckout,
  OOrderGroup,
  OSetup,
  OTerms,
  OWebProdcutList,
  PriceAgeGroup,
  ProductItem,
  Reservation,
  User,
};

export const Countries: Country[] = allCountries;

class VisBookService {
  protected http: AxiosInstance;

  constructor(baseUrl: string, id: number) {
    this.http = axios.create({
      baseURL: `${baseUrl}/${id}`,
      withCredentials: true,
    });
    this.http.interceptors.response.use(this.handleSuccess, this.handleError);
  }

  handleSuccess(response: AxiosResponse) {
    const responseReturn = response.data;
    return responseReturn;
  }

  handleError(error: AxiosError) {
    return Promise.reject(error);
  }

  /* SETUP */
  getSetup(): Promise<OSetup> {
    return this.http.get('setup');
  }

  getTerms(data: ITerms[]): Promise<OTerms> {
    return this.http.post('setup/terms', data);
  }

  /* USER */
  getUser(): Promise<User> {
    return this.http.get('/user');
  }

  /* LOGIN */
  sendEmail(email: string): Promise<NoContent> {
    const data = new FormData();
    data.append('email', email);
    return this.http.post('login/request/email', data);
  }

  validateEmail(token: string): Promise<User> {
    return this.http.get(`validation/email/${token}`);
  }

  /* WEB PRODUCTS */
  getWebProductsList(data: IWebProductList): Promise<OWebProdcutList> {
    return this.http.get(`webproducts/${data.from}/${data.to}`);
  }

  getWebProductsListById(data: IWebProductList): Promise<ProductItem> {
    return this.http.get(`webproducts/${data.from}/${data.to}/${data.id}`);
  }

  /* RESERVATIONS */
  createReservation(data: IReservation): Promise<Reservation[]> {
    return this.http.post('reservations', data);
  }

  updateReservation(
    data: IReservation,
    encryptedCompanyId: string,
    reservationId: number,
  ): Promise<NoContent> {
    return this.http.put(
      `reservations/${encryptedCompanyId}/${reservationId}`,
      data,
    );
  }

  pingReservation(data: Reservation[]): Promise<NoContent> {
    return this.http.post('reservations/ping', data);
  }

  /* CHECKOUT */
  checkoutAndCreate(data: ICheckoutCreate): Promise<OCheckout> {
    return this.http.post('checkout', data);
  }

  /* ORDER GROUPS */
  getOrderGroupById(id: number): Promise<OOrderGroup> {
    return this.http.get(`ordergroups/${id}`);
  }
}

export default VisBookService;
