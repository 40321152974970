import type { PluginOptions } from 'vue-toastification';

import { createPinia } from 'pinia';
import { createApp } from 'vue';
import Toast from 'vue-toastification';

import App from './app.vue';
import router from './router';
import './assets/main.css';

// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

const options: PluginOptions = {
  // You can set your default options here
};

const app = createApp(App);

// Sentry.init({
//   app,
//   dsn: 'https://bc0c53cd9b3ad5876390aec282ef9e87@o4508439012573184.ingest.de.sentry.io/4508439036952656',
//   integrations: [
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for tracing.
//   // We recommend adjusting this value in production
//   // Learn more at
//   // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   // Learn more at
//   // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

app.use(createPinia());
app.use(Toast, options);
app.use(router);

app.mount('#app');
