import type { Reservation } from './reservations';
import type { User } from './user';

export enum PaymentTypes {
  noOnlinePayment = 'noOnlinePayment',
  netAxept = 'netAxept',
  partialPayment = 'partialPayment',
  invoice = 'invoice',
}

export enum CheckoutStatus {
  ok = 'ok',
  someReservationsExpired = 'someReservationsExpired',
  noPayment = 'noPayment',
  invoicePayment = 'invoicePayment',
  paymentWithGiftcardsError = 'paymentWithGiftcardsError',
}

export enum CheckoutErrors {
  'Unable to reserve the amount on the card. Contact card issuer.',
  'Unable to capture the amount on the card. Contact card issuer.',
  'Unable to query the payment provider about the transaction. Contact the hotel.',
  'Unable to annul (refund) the transaction. Contact the hotel.',
  'Unable to set order to status paid. Contact the hotel.',
  'Unknown response from the payment processor. Contact the hotel.',
  'Unable to find the order. Contact the hotel.',
  'The order has been modified by someone else. Contact the hotel.',
  'Unable to save the order. Accounting error due to amount being lower than required fixed amount on this account. Contact the hotel.',
  'Unable to save the order, there\'s an error with the price. Contact the hotel.',
  'Unable to save the order, the invoice customer was not found. Contact the hotel.',
  'Some reservations have expired. Try again.',
  'Unable to process sale. Contact card issuer.',
}

export interface ICheckoutCreate {
  reservations: Reservation[];
  successUrl: string;
  errorUrl: string;
  paymentType: PaymentTypes;
  amount: number;
  customer: User;
  acceptedTerms: boolean;
  externalReference?: string;
  notes?: string;
  giftcards?: string[];
}

export interface OCheckout {
  terminalUrl: string;
  checkoutStatus: CheckoutStatus;
  expiredReservations: Reservation[] | null;
  giftcardsBalance: object | null;
}
