import type { AxiosError } from 'axios';

import { TZDate } from '@date-fns/tz';
import axios from 'axios';
import { isAfter } from 'date-fns';
import { defineStore } from 'pinia';
import { ref, type Ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

import type { ReservationItem } from '@/lib/VisBook/types/reservations';
import type { AdditionalServices, ProductItem } from '@/lib/VisBook/types/webproducts';

import { getSessionStorage, persistToSessionStorage } from '@/lib/local-storage';
import { graduationService } from '@/lib/services';
import { type OSetup, PaymentTypes, type Reservation } from '@/lib/VisBook';

import { useUserStore } from './user';

const toast = useToast();

const sharedIds = new Set([163019, 163027, 163030, 164520, 164523, 164526]);

interface GuestDetails {
  name: string;
  allergies: string;
}

export interface AvailRoom {
  webProductId: number;
  name: string;
  description: string;
  priceId: number;
  roomPrice: number;
  services: AdditionalServices[];
  totalPrice: number;
  availableRooms: number;
  totalRooms: number;
  available: boolean;
  image: string;
  is_shared: boolean;
  percent_available: number;
}

interface Cart {
  reservations: Reservation[];
  selectedRoom: AvailRoom;
  totalGuests: number;
  guestDetails: GuestDetails[];
}

export const useGraduationStore = defineStore('graduation', () => {
  const visbookSetup: Ref<OSetup | undefined> = ref();
  // @ts-expect-error this is okay
  const availTickets: Ref<CartItem[]> = ref(JSON.parse(sessionStorage.getItem('peaceAvailTickets')) || []);

  const cart: Ref<Cart> = ref(getSessionStorage('graduationCart') ?? {
    reservations: [],
    selectedRoom: null,
    totalGuests: 0,
    guestDetails: [],
  });

  const guestDetails: Ref<GuestDetails[]> = ref([]);

  const bookingOpen: Ref<boolean> = ref(true);
  const openingDate: Ref<Date | undefined> = ref();
  const paymentLoading: Ref<boolean> = ref(false);
  const loadingOpen: Ref<boolean> = ref(false);

  watch(cart, async () => {
    persistToSessionStorage('graduationCart', cart);
  }, { deep: true });

  async function getSetup() {
    visbookSetup.value = await graduationService.getSetup();
  }

  async function ping() {
    const router = useRouter();

    if (cart.value.reservations.length) {
      const res = await graduationService.pingReservation(cart.value.reservations);
      // @ts-expect-error fix later
      if (res.length) {
        cart.value = {
          reservations: [],
          // @ts-expect-error fix later
          selectedRoom: null,
          totalGuests: 0,
          guestDetails: [],
        };
        router.push({ name: 'graduationHome' });
        toast.info('Some of your reservations have expired. Please try again.');
      }
      setTimeout(async () => {
        await graduationService.pingReservation(cart.value.reservations);
        // @ts-expect-error fix later
        if (res.length) {
          cart.value = {
            reservations: [],
            // @ts-expect-error fix later
            selectedRoom: null,
            totalGuests: 0,
            guestDetails: [],
          };
          router.push({ name: 'graduationHome' });
          toast.info('Some of your reservations have expired. Please try again.');
        }
      }, 35_000);
    }
  }

  function getTotalPrice(type: ProductItem) {
    let totalPrice = type.prices[0].calculatedPrice;
    type.additionalServices.forEach((service) => {
      totalPrice += sharedIds.has(type.id) ? service.price : service.price * cart.value.totalGuests;
    });

    return totalPrice;
  }

  const availRooms: Ref<AvailRoom[]> = ref([]);

  async function getRooms() {
    if (!availRooms.value.length) {
      const roomTypes = await graduationService.getWebProductsList({
        from: '2025-05-23',
        to: '2025-05-24',
      });

      roomTypes.accommodations.forEach((type) => {
        if (type.maxPeople >= cart.value.totalGuests || sharedIds.has(type.id)) {
          availRooms.value.push({
            webProductId: type.id,
            name: type.name,
            description: type.description.short,
            priceId: type.prices[0].id,
            roomPrice: type.prices[0].calculatedPrice,
            services: type.additionalServices,
            totalPrice: getTotalPrice(type),
            availableRooms: type.availability.available ? type.availability.steps[0].availableUnits : 0,
            available: type.availability.available,
            totalRooms: type.properties.total_rooms.value || type.availability.steps[0].availableUnits,
            is_shared: !!(sharedIds.has(type.id)),
            image: `${type.images[0].transformer}/${type.images[0].imagePath}`,
            percent_available: type.availability.available ? (type.availability.steps[0].availableUnits / (type.properties.total_rooms.value || type.availability.steps[0].availableUnits)) * 100 : 100,
          });
        }
      });
    }
  }

  function fixServices(services: AdditionalServices[], totalPeople?: number) {
    const returnServices: ReservationItem[] = [];
    services.forEach((service) => {
      returnServices.push({
        count: totalPeople || cart.value.totalGuests,
        id: service.id,
        encryptedCompanyId: service.encryptedCompanyId,
      });
    });
    return returnServices;
  }

  async function bookNow(room: AvailRoom, is_shared?: boolean) {
    if (is_shared) {
      for (let i = 0; i < cart.value.totalGuests; i++) {
        const reservation = await graduationService.createReservation({
          fromDate: '2025-05-23',
          toDate: '2025-05-24',
          numberOfPeople: 1,
          priceId: room.priceId,
          webProductId: room.webProductId,
          additionalServices: fixServices(room.services, 1),
        });

        cart.value.reservations.push(reservation[0]);
      }
    }
    else {
      // BOOKING ONLY ONE ROOM!
      const reservation = await graduationService.createReservation({
        fromDate: '2025-05-23',
        toDate: '2025-05-24',
        numberOfPeople: cart.value.totalGuests,
        priceId: room.priceId,
        webProductId: room.webProductId,
        additionalServices: fixServices(room.services),
      });

      cart.value.reservations.push(reservation[0]);
    }

    cart.value.selectedRoom = room;
  };

  async function payNow(terms: boolean) {
    if (!terms) {
      toast.error('You must accept the terms.');
      throw new Error('You must accept the terms');
    }
    const userStore = useUserStore();
    paymentLoading.value = true;

    try {
      const checkout = await graduationService.checkoutAndCreate({
        acceptedTerms: terms,
        amount: cart.value.selectedRoom.is_shared ? cart.value.selectedRoom.totalPrice * cart.value.totalGuests : cart.value.selectedRoom.totalPrice,
        customer: userStore.visBookUser,
        paymentType: PaymentTypes.partialPayment,
        reservations: cart.value.reservations,
        successUrl: 'https://bookings.uwcconnect.com/confirmation?from=graduation',
        errorUrl: 'https://bookings.uwcconnect.com/graduation/checkout',
      });

      if (checkout.checkoutStatus === 'ok') {
        window.location.href = checkout.terminalUrl;
      }

      if (checkout.checkoutStatus === 'invoicePayment') {
        window.location.href = checkout.terminalUrl;
      }
    }
    catch (e) {
      const error = e as AxiosError;
      // @ts-expect-error okay
      toast.error(error.response?.data.error || error.message);

      paymentLoading.value = false;
    }
  }

  function reset() {
    availTickets.value = [];
    cart.value = {
      reservations: [],
      // @ts-expect-error okay
      selectedRoom: null,
      totalGuests: 0,
      guestDetails: [],
    };
    availRooms.value = [];
    sessionStorage.removeItem('graduationCart');
  }

  async function getBookingStatus() {
    await getSetup();
    loadingOpen.value = true;
    const { data: serverDate } = await axios.get('https://timeapi.io/api/time/current/zone?timeZone=Europe%2FOslo');

    openingDate.value = new TZDate(visbookSetup.value?.properties.open_datetime.text, 'Europe/Oslo');

    bookingOpen.value = isAfter(serverDate.dateTime, openingDate.value);

    loadingOpen.value = false;
  }

  return { reset, fixServices, getBookingStatus, loadingOpen, visbookSetup, getSetup, getRooms, bookNow, guestDetails, ping, cart, availRooms, payNow, paymentLoading, availTickets, bookingOpen, openingDate };
});
