import { defineStore } from 'pinia';
import { ref, type Ref, watch } from 'vue';
import { useToast } from 'vue-toastification';

import type { User } from '@/lib/VisBook';

import { persistToSessionStorage } from '@/lib/local-storage';
import { reunionService } from '@/lib/services';

const toast = useToast();

export const useUserStore = defineStore('user', () => {
  // @ts-expect-error this is okay
  const visBookUser: Ref<User> = ref(JSON.parse(sessionStorage.getItem('visBookUser')));
  const codeSent: Ref<boolean> = ref(false);

  watch(visBookUser, async () => {
    persistToSessionStorage('visBookUser', visBookUser);
  }, { deep: true });

  async function sendEmail(email: string) {
    try {
      await reunionService.sendEmail(email);
      toast.success('We have just sent you the code to your email!');
      codeSent.value = true;
    }
    catch (error) {
      console.error(error);

      toast.error('Please check your email address and try again.');
      throw new Error('Please check your email address and try again.');
    }
  }

  async function verifyToken(token: string) {
    if (token.length) {
      try {
        const user = await reunionService.validateEmail(token);
        visBookUser.value = user;
        return true;
      }
      catch (e) {
        console.error(e);
        toast.error('Please check your code and try again.');
        throw new Error('Please check your code and try again.');
      }
    }
    toast.error('Please check your code and try again.');
    throw new Error('Please check your code and try again.');
  }

  async function getUser() {
    if (!visBookUser.value) {
      try {
        const user = await reunionService.getUser();
        visBookUser.value = user;
        return true;
      }
      catch (error) {
        console.error(error);
        return false;
      }
    }
    else {
      return visBookUser.value;
    }
  }

  return { visBookUser, codeSent, sendEmail, verifyToken, getUser };
});
